<template>
  <div class="pa-16">
    <div class="d-flex justify-space-between align-center mb-4">
      <h1 class="text-h4 font-weight-light ">Налаштування доменної адреси </h1>
<!--      <v-btn @click="saveChanges" rounded outlined dark large  color="deep-purple">-->
<!--        <v-icon class="mr-2">mdi-check</v-icon>Зберегти-->
<!--      </v-btn>-->
    </div>





    <h2 class=" font-weight-light mb-16"> {{ titleText }} </h2>

    <!--    <v-text-field  v-model="domainName" label="Domain Name" />-->


    <!-- DOMAIN EXISTS -->
    <div v-if="domain">
      <v-simple-table>
        <thead>
        <tr>
          <th width="200px"></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td >Домен</td>
          <td><a :href="domain.domain" target="_blank"><h2 class="font-weight-light">{{ domain.domain }}</h2> </a> </td>
        </tr>
        <tr>
          <td>Активний до</td>
          <td> {{ domain.duration }}</td>
        </tr>
        <tr>
          <td colspan="2">Продовжити</td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>

    <!-- NO DOMAIN -->
    <div v-else>

      <!-- ORDER EXIST-->
      <v-sheet v-if="order" class="pb-8 pt-0" >
        <h4 class="text-right font-weight-light mt-12">Замовлення: {{  order.fine_id  }}</h4>
        <v-divider class="mt-4"></v-divider>
        <div class="d-flex align-center justify-space-between mt-8">
          <v-btn icon class="mt-1"  @click="showCancelConfirm"><v-icon>mdi-close</v-icon></v-btn>
          <h1 class=" font-weight-light">{{  order.domain  }}</h1>

          <h1 class="font-weight-light  text-no-wrap text--lighten-3"> 1 рік </h1>
          <h3 class="text-h4 teal--text text-no-wrap text--lighten-2">{{ fine_reciept.price  }} <span>{{  fine_reciept.currency  }}</span></h3>

          <liqBtn v-if="liqButtonData" :liq-data="liqButtonData" >
            <v-btn large type="submit" dark rounded color="teal lighten-2" >
              <v-icon class="mr-2">mdi-cart</v-icon>
              Оплатити
            </v-btn>
          </liqBtn>
          <!--        <v-btn large  dark rounded color="teal lighten-2" @click="commitPayment(order)">-->
          <!--          <v-icon class="mr-2">mdi-cart</v-icon>-->
          <!--          Оплатити-->
          <!--        </v-btn>-->
        </div>
        <v-divider class="mt-8" ></v-divider>
      </v-sheet>

      <!-- SEARCH DOMAIN-->
      <v-row v-if="!order"  align="center">
        <v-col cols="10">
          <v-text-field  :loading="loading" filled rounded v-model="domainNameForCheck" label="Перевірити адресу" />
        </v-col>
        <v-col cols="2" align="end" >
          <v-btn large class="mb-8" dark rounded color="teal lighten-2" @click="checkDomain">
            <v-icon class="mr-2">mdi-magnify</v-icon> Перевірити</v-btn>
        </v-col>

      </v-row>
      <v-sheet class="pa-8" v-if="searchResult && !order">
        <h2 class="text-center font-weight-light">Вітаємо! Ваш домен доступний для придбання ! </h2>
        <v-divider class="mt-8"></v-divider>
        <div class="d-flex justify-space-between mt-8">
          <h3 class="text-h3 font-weight-light">{{  searchResult.domain  }}</h3>
          <div>
            <h3 class="text-h4 teal--text text-no-wrap text--lighten-2">{{searchResult.zone.price_action ? searchResult.zone.price_action : searchResult.zone.price  }} <span>грн</span></h3>
            <div class="d-flex">
              <h4 v-if="searchResult.zone.price_action" class="text-caption text-right grey--text text--darken-2">{{ searchResult.zone.price }}</h4>
              <h4 class="ml-1 text-caption text-right grey--text text--darken-2">щорічно</h4>
            </div>
          </div>
          <v-btn large  dark rounded color="teal lighten-2" @click="orderDomain(searchResult.domain,2)">
            <v-icon class="mr-2">mdi-cart</v-icon>
            Замовити
          </v-btn>
        </div>
        <v-divider class="mt-6" ></v-divider>
      </v-sheet>

      <!-- CANCEL ORDER CONFIRMATION -->
      <v-dialog v-if="order" width="700" v-model="displayConfirmation">
        <v-sheet class="py-6 px-8"  >
          <div class="d-flex justify-space-between align-center mt-2 ">
            <h2 class=" font-weight-light">Підтвердження операції</h2>
            <v-btn plain icon  @click="displayConfirmation = false"><v-icon>mdi-close</v-icon></v-btn>
          </div>
          <v-sheet outlined rounded  class="mt-8 pt-8 pb-8 px-6"  >
            <h2 class="font-weight-light text-center"> {{ confirmationText }}</h2>
            <h3 class="text-center mt-6"> {{ order.domain }} </h3>
          </v-sheet>
          <!-- Navigation-->
          <div class="d-flex justify-end mt-5 mb-3">
            <v-btn  :disabled="loading"  @click="displayConfirmation = false"
                    large class="mt-3 px-8" text rounded elevation="0"  >
              <h3 class="font-weight-light">Ні</h3>
            </v-btn>
            <v-btn :disabled="loading" :loading="loading"  @click="confirmCancelation"
                   large class="mt-3 px-8" rounded outlined elevation="0"  >
              <h3 class="font-weight-light">Так</h3>
            </v-btn>
          </div>

        </v-sheet>
      </v-dialog>

    </div>




  </div>
</template>

<script>

import liqBtn from "@/components/UI/liqBtn";

import {mapActions, mapState} from "vuex";

export default {
  name: "BusinessDashDomain",
  components : {
    liqBtn
  },
  data(){
    return {
      loading : false,

      liqButtonData : null,

      displayConfirmation: false,
      confirmationText : 'Ви впевнені що хочете скасувати замовлення доменної адреси :',

      domain : null,
      domainName  : '',
      domainNameForCheck : '',
      searchResult : null,

      order : null,
      fine_reciept : null,
    }
  },
  computed : {
    ...mapState('business', ['selectedBusiness']),
    titleText(){
      if (this.order) { return "Для підключення власної доменної адреси оплатіть ваше замовлення : " }
      if (this.domain) { return "Доменна адреса налаштована  " }
      return "Для замовлення власної адреси знайдіть доступне доменне ім'я та зробіть замовлення"
    }
  },
  methods : {
    ...mapActions('domains', ['CHECK_DOMAIN','ORDER_DOMAIN','GET_DOMAIN_STATUS']),
    ...mapActions('liqpay', ['CHECKOUT_AUTH']),

    checkDomain(){
      this.loading = true
      this.CHECK_DOMAIN( {domain : this.domainNameForCheck} )
      .then((out)=>{
        if (out.result && out.data.result) {
          this.searchResult = out.data.response
        }
        this.loading = false
      })
      .catch(()=>{
        this.searchResult = null
        this.loading = false
      })
    },
    orderDomain(domain,period){
      this.loading = true
      this.ORDER_DOMAIN( {
        alias : this.selectedBusiness.alias,
        data : {
          domain : domain,
          period : period,
          href : location.href
        }
      }).then((out)=>{
        if (out.result ) {
          this.order         = out.data.order
          this.liqButtonData = out.data.liq_button
          this.fine_reciept  = out.data.fine_reciept
        }
        this.loading = false
      }).catch(()=>{

        this.searchResult = null
        this.loading = false

      })
    },
    commitPayment(order) {
      console.log(order)
      this.loading = true
      this.CHECKOUT_AUTH({order : order.domain, return_href : location.href}).then((out)=>{
        console.log(out)
        this.loading = false

      }).catch(()=>{this.loading = false})

    },
    confirmCancelation(){

      this.notify(`Domain name order No ${this.order.order_id} canceled`)
      this.displayConfirmation = false

    },

    showCancelConfirm(){
      this.displayConfirmation = true
    },

    saveChanges(){
      let data = { payments : [] };
      this.payments.forEach((payment)=>{
        if (payment.value) { data.payments.push(payment.id) }
      })
      this.loading = true
      this.EDIT_PAYMENT_TYPES({alias:this.selectedBusiness.alias,data:data}).then((out)=>{
        console.log(out)
        this.loading = false
      }).catch(()=>{this.loading = false})
    }
  },
  mounted(){
    this.loading = true
    this.GET_DOMAIN_STATUS({ alias : this.selectedBusiness.alias, data : { href: location.href } } )
        .then((out)=>{
          if (out.data) {

            if (out.data.domain) {

              this.domain = out.data.domain

            } else {

              this.order         = out.data.order
              this.liqButtonData = out.data.liq_button
              this.fine_reciept  = out.data.fine_reciept

            }

          }
          this.loading = false
        })
        .catch(()=>{
          this.loading = false
        })

    this.domainName = this.selectedBusiness.domain



  }
}
</script>

<style scoped>

</style>